// src/App.js
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

//import des pages
import Header from './components/Header';
import Index from './components/Index';
import Gallery from './components/Gallery';
import Footer from './components/Footer';
import Contact from './components/Contact';
import Login from './components/Login';
import Admin from './components/Admin';
import Account from './components/Account';
import Youtube from './components/Youtube';
import Video from './components/Video';
import Settings from './components/Settings';
import PrestaTourisme from './components/Tourisme';
import PrestaInspection from './components/Inspection';
import PrestaEvent from './components/Evenementiels';
import Immobiliers from './components/Immobiliers';
import DroneFpv from './components/DroneFpv';
import VenteRush from './components/VenteRush';
import MentionLegales from './components/MentionLegales';
import CategoryAdmin from './components/Category';
import PolitiqueConfident from './components/PolitiqueConfident';
import MaintenanceWrapper from './components/MaintenanceWrapper';
import { AuthProvider, useAuth } from './auth';
import { initGA, logPageView } from './analytics'; // Importez vos fonctions d'analytics

const TrackPage = () => {
  const location = useLocation();

  useEffect(() => {
    logPageView(location.pathname); // Enregistrer une vue de page � chaque changement de route
  }, [location]);

  return null;
};

const App = () => {
  useEffect(() => {
    initGA(); // Initialiser Google Analytics lors du montage du composant
  }, []);

  return (
    <AuthProvider>
      <HelmetProvider>
        <Router>
          <MaintenanceWrapper>
            <TrackPage /> {/* Ajoutez le composant de suivi des pages */}
            <Header />
            <Routes>
              <Route path="/" element={<Index />} />
              <Route path="/admin/login" element={<Login />} />
              <Route path="/admin/index" element={<PrivateRoute component={<Admin />} />} />
              <Route path="/admin/account" element={<PrivateRoute component={<Account />} />} />
              <Route path="/admin/youtube" element={<PrivateRoute component={<Youtube />} />} />
              <Route path="/admin/settings" element={<PrivateRoute component={<Settings />} />} />
              <Route path="/admin/categories" element={<PrivateRoute component={<CategoryAdmin />} />} />
              
              <Route path="/contact" element={<Contact />} />
              <Route path="/video" element={<Video />} />
              <Route path="/tourisme-documentaires" element={<PrestaTourisme />} />
              <Route path="/inspection-techniques" element={<PrestaInspection />} />
              <Route path="/institutionnelles-evenementiels" element={<PrestaEvent />} />
              <Route path="/immobiliers" element={<Immobiliers />} />
              <Route path="/drone-fpv" element={<DroneFpv />} />
              <Route path="/vente-rush-videos-photos" element={<VenteRush />} />
              <Route path="/mentions-legales" element={<MentionLegales />} />
              <Route path="/politique-de-confidentialite" element={<PolitiqueConfident />} />
              <Route path="/portfolio" element={<Gallery />} />
            </Routes>
            <Footer />
          </MaintenanceWrapper>
        </Router>
      </HelmetProvider>
    </AuthProvider>
  );
};

const PrivateRoute = ({ component }) => {
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? component : <Navigate to="/admin/login" />;
};

export default App;
