// src/pages/Politique.js
import React from 'react';
import styled from 'styled-components';
import { Typography, Container } from '@mui/material';

const Section = styled.section`
  margin: 20px 0;
`;

const Politique = () => {
  return (
    <Container maxWidth="md" sx={{ mt :4 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Politique de Confidentialité de Footage-Now
      </Typography>

      <Typography variant="body2" color="textSecondary" gutterBottom>
        Dernière mise à jour : 14 janvier 2025
      </Typography>

      <Section>
        <Typography variant="body1" paragraph>
          Chez Footage-Now, nous respectons votre vie privée et nous nous engageons à protéger les informations personnelles que vous partagez avec nous. Cette politique de confidentialité explique quelles données nous collectons, comment nous les utilisons et vos droits en tant qu'utilisateur.
        </Typography>
      </Section>

      <Section>
        <Typography variant="h6" component="h2" gutterBottom>
          1. Données collectées
        </Typography>
        <Typography variant="body1" paragraph>
          Nous collectons uniquement les informations suivantes via notre page de contact :
        </Typography>
        <ul>
          <li><Typography variant="body1">Nom de famille</Typography></li>
          <li><Typography variant="body1">Société</Typography></li>
          <li><Typography variant="body1">Numéro de téléphone</Typography></li>
        </ul>
        <Typography variant="body1" paragraph>
          Ces informations sont recueillies lorsque vous remplissez le formulaire de contact afin de nous permettre de répondre à vos demandes et de vous fournir des informations pertinentes.
        </Typography>
      </Section>

      <Section>
        <Typography variant="h6" component="h2" gutterBottom>
          2. Utilisation des données
        </Typography>
        <Typography variant="body1" paragraph>
          Les informations collectées sont utilisées exclusivement pour :
        </Typography>
        <ul>
          <li><Typography variant="body1">Répondre à vos demandes via le formulaire de contact.</Typography></li>
          <li><Typography variant="body1">Établir un suivi si nécessaire.</Typography></li>
        </ul>
        <Typography variant="body1" paragraph>
          Vos données ne seront ni vendues, ni louées, ni partagées avec des tiers, sauf si la loi nous y oblige.
        </Typography>
      </Section>

      <Section>
        <Typography variant="h6" component="h2" gutterBottom>
          3. Cookies utilisés
        </Typography>
        <Typography variant="body1" paragraph>
          Notre site utilise un seul cookie pour :
        </Typography>
        <ul>
          <li><Typography variant="body1">Enregistrer votre consentement à l'utilisation des cookies (cookie d'acceptation).</Typography></li>
        </ul>
        <Typography variant="body1" paragraph>
          Ce cookie ne collecte aucune information personnelle et sert uniquement à mémoriser vos préférences en matière de confidentialité.
        </Typography>
      </Section>

      <Section>
        <Typography variant="h6" component="h2" gutterBottom>
          4. Conservation des données
        </Typography>
        <Typography variant="body1" paragraph>
          Les données personnelles collectées via le formulaire de contact sont conservées uniquement le temps nécessaire pour répondre à votre demande ou pour satisfaire nos obligations légales.
        </Typography>
      </Section>

      <Section>
        <Typography variant="h6" component="h2" gutterBottom>
          5. Sécurité des données
        </Typography>
        <Typography variant="body1" paragraph>
          Nous mettons en œuvre des mesures techniques et organisationnelles pour protéger vos données contre tout accès non autorisé, perte ou divulgation.
        </Typography>
      </Section>

      <Section>
        <Typography variant="h6" component="h2" gutterBottom>
          6. Vos droits
        </Typography>
        <Typography variant="body1" paragraph>
          Conformément au Règlement Général sur la Protection des Données (RGPD) et aux lois applicables, vous disposez des droits suivants :
        </Typography>
        <ul>
          <li><Typography variant="body1">Accéder à vos données personnelles.</Typography></li>
          <li><Typography variant="body1">Rectifier vos données si elles sont inexactes ou incomplètes.</Typography></li>
          <li><Typography variant="body1">Demander la suppression de vos données.</Typography></li>
          <li><Typography variant="body1">Vous opposer à leur traitement.</Typography></li>
          <li><Typography variant="body1">Retirer votre consentement à tout moment pour les données traitées sur cette base.</Typography></li>
        </ul>
        <Typography variant="body1" paragraph>
          Pour exercer vos droits, veuillez nous contacter via la page contact.
        </Typography>
      </Section>

      <Section>
        <Typography variant="h6" component="h2" gutterBottom>
          7. Modifications de la politique de confidentialité
        </Typography>
        <Typography variant="body1" paragraph>
          Nous nous réservons le droit de modifier cette politique de confidentialité à tout moment. Toute modification sera publiée sur cette page avec une date de mise à jour.
        </Typography>
      </Section>

      <Section>
        <Typography variant="h6" component="h2" gutterBottom>
          8. Contact
        </Typography>
        <Typography variant="body1" paragraph>
          Si vous avez des questions ou des préoccupations concernant cette politique de confidentialité, vous pouvez nous contacter à :
        </Typography>
        <Typography variant="body1" paragraph>
          Merci de nous contacter via la page contact.
        </Typography>
        <Typography variant="body1" paragraph>
        </Typography>
      </Section>
    </Container>
  );
};

export default Politique;
