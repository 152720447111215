import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Grid, Typography, Box, Button, Card, CardMedia, CardContent, Container, Divider } from '@mui/material';

const InstitutionnellesEvenementiels = () => {
  return (
    <>
    <Helmet>
      <title>Prestation Drone Institutionnel & Événementiel - Vidéos Aériennes de Qualité à Montbéliard, Belfort, Héricourt - Footage-Now</title>
      <meta name="description" content="Capturez des moments uniques grâce à nos prestations drone pour vos événements institutionnels et privés. Vidéos aériennes en haute qualité, idéales pour valoriser vos projets." />
    </Helmet>

    <Box sx={{ py: 0 }}>
      <Container maxWidth="lg">
        {/* En-tête */}
        <Typography variant="h3" align="center" gutterBottom sx={{ fontWeight: 'bold' }}>
          Prestations Drone pour Institutions & Événements
        </Typography>
        <Typography variant="h6" align="center" paragraph>
          Valorisez vos événements et productions institutionnelles grâce à des images aériennes d'exception. 
          Faites briller votre marque avec des vidéos qui marquent les esprits à <strong>Montbéliard</strong>, <strong>Belfort</strong>, et <strong>Héricourt</strong>.
        </Typography>

        <Divider sx={{ my: 2 }} />

        {/* Contenu principal */}
        <Grid container spacing={4} sx={{ mt: 1 }}>
          {/* Section Institutionnelles */}
          <Grid item xs={12} md={6}>
            <Card sx={{ boxShadow: 3 }}>
              <CardMedia
                component="img"
                height="300"
                image="/assets/images/institutionnelle.jpg"
                alt="Institutionnel vu par drone"
              />
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  Productions institutionnelles impactantes
                </Typography>
                <Typography variant="body1" paragraph>
                  Faites passer vos messages institutionnels avec élégance et professionnalisme. Nos drones capturent 
                  des images qui reflètent votre identité et renforcent votre crédibilité. 
                </Typography>
                <Typography variant="body1" paragraph>
                  Que ce soit pour des vidéos d'entreprise, des présentations officielles ou des campagnes de communication, 
                  nos services offrent une qualité cinématographique pour transmettre vos valeurs avec impact.
                </Typography>
                <Typography variant="body1" paragraph>
                  Offrez à votre public une nouvelle perspective qui distingue votre marque et suscite l’adhésion.
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          {/* Section Événementiels */}
          <Grid item xs={12} md={6}>
            <Card sx={{ boxShadow: 3 }}>
              <CardMedia
                component="img"
                height="300"
                image="/assets/images/evenementiel.jpg"
                alt="Événement capturé par drone"
              />
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  Événements mémorables en images
                </Typography>
                <Typography variant="body1" paragraph>
                  Sublimez vos événements avec des prises de vue aériennes qui capturent chaque instant magique. 
                  Nos drones vous permettent de documenter vos concerts, festivals, mariages, conférences ou 
                  lancements de produits sous des angles spectaculaires.
                </Typography>
                <Typography variant="body1" paragraph>
                  Transformez vos souvenirs en œuvres d’art visuelles et partagez des moments forts qui inspirent 
                  votre audience.
                </Typography>
                <Typography variant="body1" paragraph>
                  Que vous organisiez un événement privé ou public, nos prestations garantissent un rendu 
                  exceptionnel pour marquer les esprits.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <Divider sx={{ my: 5 }} />

        {/* Section Événements Privés */}
        <Box sx={{ mt: 4}}>
          <Card sx={{ boxShadow: 3 }}>
            <Grid container spacing={2}>
              {/* Image */}
              <Grid item xs={12} md={6}>
                <CardMedia
                  component="img"
                  height="100%"
                  image="/assets/images/evenements-prives.jpg"
                  alt="Événements privés filmés par drone"
                />
              </Grid>
              {/* Texte */}
              <Grid item xs={12} md={6}>
                <CardContent>
                  <Typography variant="h5" gutterBottom>
                    Événements Privés
                  </Typography>
                  <Typography variant="body1" paragraph>
                  Faites de vos événements privés des souvenirs inoubliables grâce à nos prestations de prises de vue aérienne par drone.
                  Que ce soit pour un mariage féerique, une réunion de famille émouvante ou une célébration spéciale, nos drones capturent chaque instant sous un angle unique et spectaculaire.
                  </Typography>
                  <Typography variant="body1" paragraph>
                  Imaginez vos moments les plus précieux filmés dans des cadres enchanteurs : somptueux châteaux, vastes domaines ou paysages naturels grandioses. 
                  Nos prises de vue aériennes apportent une dimension cinématographique à vos événements en plein air, sublimant chaque détail et chaque émotion.
                  </Typography>
                  <Typography variant="body1" paragraph>
                  Ces images exceptionnelles trouveront leur place dans des montages photo ou vidéo conçus pour vous permettre de revivre, encore et encore, la magie de ces instants partagés. 
                  Faites le choix d’un service professionnel et artistique pour célébrer vos plus beaux souvenirs.
                  Votre événement mérite une perspective unique. Contactez-nous pour donner des ailes à vos souvenirs.
                  </Typography>
                </CardContent>
              </Grid>
            </Grid>
          </Card>
        </Box>

        <Divider sx={{ my: 5 }} />

        {/* Section d'appel à l'action */}
        <Box sx={{ textAlign: 'center', mt: 6 }}>
          <Typography variant="h4" gutterBottom>
            Mettez vos projets en lumière !
          </Typography>
          <Typography variant="body1" paragraph>
            Faites appel à notre expertise pour des visuels qui impressionnent et inspirent. Contactez-nous dès maintenant 
            pour discuter de vos besoins et obtenir un devis personnalisé.
          </Typography>
          <Button variant="contained" color="primary" size="large" href="/contact">
            Contactez-nous
          </Button>
        </Box>
      </Container>
    </Box>
    </>
  );
};

export default InstitutionnellesEvenementiels;
