import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Container, Grid, Typography, Card, CardContent, CardMedia, Button, Divider, Box } from '@mui/material';
import { Link } from 'react-router-dom';

const VenteRush = () => {
  return (
    <>
    <Helmet>
      <title>Vente de rushs vidéos et photographie avec licence à Montbéliard, Belfort, Héricourt - Footage-Now</title>
      <meta name="description" content="Accédez à des vidéos et photos de qualité professionnelle pour enrichir vos projets. Découvrez notre vaste sélection de rushs vidéo et photos, capturés avec des drones et des caméras de haute qualité, disponibles à la vente." />
    </Helmet>

    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      {/* Section d'introduction */}
      <Box textAlign="center" mb={4}>
        <Typography variant="h3" component="h1" gutterBottom className="h1-no-margin">
          Vente Rushs et Photos
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom>
          Achetez des Rushs Vidéo et Photos en Licence Standard, Exclusivité ou Propriété
        </Typography>
        <Typography variant="body1" paragraph>
          Accédez à des vidéos et photos de qualité professionnelle pour enrichir vos projets. Découvrez notre vaste sélection de rushs vidéo et photos, capturés avec des drones et des caméras de haute qualité, disponibles à la vente. Que ce soit pour des besoins commerciaux, créatifs ou personnels, nos rushs et photos vous offrent des images exceptionnelles pour enrichir vos contenus visuels.
        </Typography>
      </Box>

      <Divider />

      {/* Pourquoi acheter des rushs vidéo et photos */}
      <Box textAlign="center" my={4}>
        <Typography variant="h5" component="h2">
          Pourquoi Acheter des Rushs Vidéo et Photos ?
        </Typography>
      </Box>

      <Grid container spacing={4}>
        <Grid item xs={12} md={4}>
          <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <CardContent>
              <Typography variant="h5" component="h3">
                Gain de Temps et d'Effort
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Accédez instantanément à des vidéos et photos de haute qualité, sans besoin de production supplémentaire.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <CardContent>
              <Typography variant="h5" component="h3">
                Options Flexibles de Licence
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Choisissez entre une licence standard, exclusive ou de propriété, selon vos besoins spécifiques.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <CardContent>
              <Typography variant="h5" component="h3">
                Sélection Diversifiée de Contenus
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Explorez une variété de rushs vidéo et de photos couvrant différents thèmes et perspectives, idéals pour tous types de projets.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Divider sx={{ my: 4 }} />

      {/* Nos options de licence */}
      <Typography variant="h5" component="h2" align="center" gutterBottom>
        Options de Licence
      </Typography>

      <Grid container spacing={4}>
        <Grid item xs={12} md={4}>
          <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <CardMedia
              component="img"
              height="200"
              image="/assets/images/licence_standard.jpg"
              alt="Licence Standard"
            />
            <CardContent>
              <Typography variant="h5" component="h3">
                Licence Standard
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Permet une utilisation non-exclusive des vidéos et photos pour des projets variés, sans limite de diffusion.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <CardMedia
              component="img"
              height="200"
              image="/assets/images/licence_exclusivite.jpg"
              alt="Licence d'Exclusivité"
            />
            <CardContent>
              <Typography variant="h5" component="h3">
                Licence d'Exclusivité
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Offre un usage exclusif des rushs et photos, garantissant que ces médias ne seront pas disponibles pour d'autres clients.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <CardMedia
              component="img"
              height="200"
              image="/assets/images/licence_propriete.jpg"
              alt="Licence de Propriété"
            />
            <CardContent>
              <Typography variant="h5" component="h3">
                Licence de Propriété
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Acquisition complète des droits de propriété des vidéos et photos, vous offrant une flexibilité totale pour les modifier, revendre ou réutiliser sans restrictions.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Divider sx={{ my: 4 }} />

      {/* Pourquoi nos rushs vidéo et photos */}
      <Typography variant="h5" component="h2" align="center" gutterBottom>
        Pourquoi Choisir Des Rushs Vidéo et Photos ?
      </Typography>
      <Typography variant="body1" paragraph align="center">
        Depuis de nombreuses années, nous nous spécialisons dans la production de vidéos et photos de haute qualité. Nos rushs et photos offrent une perspective unique et immersive, idéale pour des secteurs tels que la publicité, le cinéma et les médias. Chaque média est soigneusement sélectionné pour répondre aux standards professionnels et captiver votre audience.
      </Typography>
      <Typography variant="body1" paragraph align="center">
        Contactez-nous dès aujourd'hui pour discuter de vos besoins en rushs vidéo et photos, et découvrir comment nos options de licence peuvent transformer vos projets en succès mémorables.
      </Typography>

      <Divider sx={{ my: 4 }} />

      {/* Contact */}
      <Box textAlign="center" my={4}>
        <Typography variant="h5" component="h2" gutterBottom>
          Contactez-nous
        </Typography>
        <Typography variant="body1" paragraph>
          Contactez moi dès aujourd'hui pour discuter de vos projets et voir comment nos rushs vidéo et photos peuvent enrichir vos contenus. Ensemble, nous créerons des visuels captivants qui marqueront votre audience.
        </Typography>
        <Button variant="contained" color="primary" component={Link} to="/contact">
          Contactez-nous
        </Button>
      </Box>

      <Divider sx={{ my: 4 }} />

      <Typography variant="h6" component="h3" align="center" gutterBottom>
        Footage-Now - La qualité professionnelle au service de vos projets.
      </Typography>
    </Container>
    </>
  );
};

export default VenteRush;
