import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Container, Grid, Typography, Card, CardContent, CardMedia, Button, Divider, Box } from '@mui/material';
import { Link } from 'react-router-dom';

const PrestaFpv = () => {
  return (
    <>
    <Helmet>
      <title>Prestation Drone FPV - Vidéos Immersives et Dynamique Aérienne à Montbéliard, Belfort, Héricourt - Footage-Now</title>
      <meta name="description" content="Explorez une nouvelle dimension avec nos prestations drone FPV. Réalisez des vidéos immersives et spectaculaires, idéales pour événements, clips ou productions créatives." />
    </Helmet>

    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      {/* Section d'introduction */}
      <Box textAlign="center" mb={4}>
        <Typography variant="h3" component="h1" gutterBottom className="h1-no-margin">
          Prestation Drone FPV
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom>
          Des Prises de Vue Dynamiques et Immersives
        </Typography>
        <Typography variant="body1" paragraph>
          Découvrez l'art de la captation avec des drones FPV. Offrant une expérience visuelle unique et des perspectives captivantes, ces drones apportent une nouvelle dimension à vos vidéos.
        </Typography>
      </Box>

      <Divider />

      {/* Pourquoi choisir un drone FPV */}
      <Box textAlign="center" my={4}>
        <Typography variant="h5" component="h2">
          Pourquoi Choisir un Drone FPV ?
        </Typography>
      </Box>

      <Grid container spacing={4}>
        <Grid item xs={12} md={4}>
          <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <CardContent sx={{ flexGrow: 1 }}>
              <Typography variant="h5" component="h3">
                Expérience Immersive
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Plongez dans une expérience visuelle unique, avec des perspectives inédites qui captivent l’audience.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <CardContent sx={{ flexGrow: 1 }}>
              <Typography variant="h5" component="h3">
                Prises de Vue Dynamiques
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Les drones FPV ajoutent une dimension cinématographique à vos vidéos, rendant chaque scène plus intense et réaliste.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <CardContent sx={{ flexGrow: 1 }}>
              <Typography variant="h5" component="h3">
                Flexibilité et Agilité
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Idéal pour capturer des séquences dans des espaces exigus ou pour des plans en mouvement rapide, offrant des images inoubliables.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Divider sx={{ my: 4 }} />

      {/* Nos Équipements de Pointe */}
      <Typography variant="h5" component="h2" align="center" gutterBottom>
        Nos Équipements de Pointe
      </Typography>

      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <CardMedia
              component="img"
              height="200"
              image="/assets/images/defender-20.jpg"
              alt="iFlight Denfer 20"
            />
            <CardContent sx={{ flexGrow: 1 }}>
              <Typography variant="h5" component="h3">
                Defender 20
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Ce drone compact et agile, équipé d'un système de protection des hélices, est parfait pour naviguer dans des environnements complexes comme les forêts ou les structures urbaines.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <CardMedia
              component="img"
              height="200"
              image="/assets/images/chimera-7-pro-v2.jpg"
              alt="Volador 6D avec GoPro"
            />
            <CardContent sx={{ flexGrow: 1 }}>
              <Typography variant="h5" component="h3">
                Chimera 7 Pro v2 avec GoPro
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Conçu pour des plans dynamiques, ce drone est équipé d'une GoPro Hero 12, offrant des vidéos de haute qualité et un rendu professionnel.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Divider sx={{ my: 4 }} />

      {/* Pour un Rendu Professionnel */}
      <Typography variant="h5" component="h2" align="center" gutterBottom>
        Pour un Rendu Professionnel !
      </Typography>
      <Typography variant="body1" paragraph align="center">
        Depuis plusieurs années, l’utilisation des drones FPV s'est imposée dans la création de contenus audiovisuels percutants. Que ce soit pour des vidéos promotionnelles audacieuses ou des documentaires fascinants, ces drones sont essentiels pour dynamiser vos images avec une profondeur et une amplitude inégalées.
      </Typography>
      <Typography variant="body1" paragraph align="center">
        Contactez-nous dès aujourd'hui pour discuter de votre projet et découvrir comment mes prestations en drone FPV peuvent transformer vos idées en vidéos captivantes et immersives.
      </Typography>

      <Divider sx={{ my: 4 }} />

      {/* Contact */}
      <Box textAlign="center" my={4}>
        <Typography variant="h5" component="h2" gutterBottom>
          Contactez-nous
        </Typography>
        <Typography variant="body1" paragraph>
          Contactez-nous dès aujourd'hui pour discuter de vos projets et voir comment mes services peuvent vous aider à atteindre vos objectifs. Ensemble, nous créerons des souvenirs visuels qui inciteront à l'exploration et à l'évasion.
        </Typography>
        <Button variant="contained" color="primary" component={Link} to="/contact">
          Contactez-nous
        </Button>
      </Box>

      <Divider sx={{ my: 4 }} />

      <Typography variant="h6" component="h3" align="center" gutterBottom>
        Footage-Now - Capturer l'instant, inspirer le voyage.
      </Typography>
    </Container>
  </>
  );
};

export default PrestaFpv;
