import React, { useState } from 'react';
import { Grid, Typography, TextField, Button, Box, Alert } from '@mui/material';
import { sendmail } from '../api'; // Importer la fonction sendmail

const Contact = () => {
  // Déclarations des états avec useState
  const [formErrors, setFormErrors] = useState({});
  const [formData, setFormData] = useState({
    name: '',
    company: '',
    mail: '',
    phone: '',
    message: '',
    appointment: null,
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  //const handleDateChange = (newValue) => {
  //  setFormData((prev) => ({ ...prev, appointment: newValue }));
  //};

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation des champs requis
    if (!formData.name || !formData.mail || !formData.message) {
      setError('Veuillez remplir tous les champs requis.');
      return;
    }

    try {
      // Appel à la fonction sendmail
      await sendmail(
        formData.name,
        formData.company,
        formData.mail,
        formData.phone,
        formData.message,
        formData.appointment
      );
      setSuccess(true);
      setError('');
      setFormData({ name: '', company: '', mail: '', phone: '', message: '', appointment: null });
    } catch (err) {
      setError(err.message || 'Une erreur est survenue lors de l\'envoi du formulaire.');
      setSuccess(false);
    }
  };

  return (
    <Box sx={{ minHeight: '50vh', mt: 4, mb: 4 }}>
      <Grid container spacing={4}>
        {/* Texte à gauche */}
        <Grid item xs={12} md={6}>
          <Typography variant="h4" gutterBottom>
            Demandez des renseignements ou un devis
          </Typography>
          <Typography variant="body1" paragraph>
            Vous avez des questions ou souhaitez obtenir un devis pour vos projets ?
            Remplissez le formulaire de contact et je vous répondrai dans les plus brefs délais.
          </Typography>
          <Typography variant="body1" paragraph>
            Vous pouvez également réserver un rendez-vous téléphonique pour discuter de vos besoins plus en détail.
          </Typography>
        </Grid>

        {/* Formulaire à droite */}
        <Grid item xs={12} md={6}>
          {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
          {success && <Alert severity="success" sx={{ mb: 2 }}>Votre message a été envoyé avec succès.</Alert>}
          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              label="Nom"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
              sx={{ mb: 2 }}
            />
            <TextField
              fullWidth
              label="Société"
              name="company"
              value={formData.company}
              onChange={handleChange}
              sx={{ mb: 2 }}
            />
            <TextField
              fullWidth
              label="Adresse Mail"
              name="mail"
              value={formData.mail}
              onChange={(e) => {
                const email = e.target.value;
                handleChange(e);

                // Vérification du format de l'email
                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Expression régulière pour valider un email
                if (!emailRegex.test(email)) {
                  setFormErrors((prev) => ({
                    ...prev,
                    mail: 'Le format de l\'adresse mail est invalide.',
                  }));
                } else {
                  setFormErrors((prev) => {
                    const { mail, ...rest } = prev; // Retirer l'erreur s'il n'y a plus de problème
                    return rest;
                  });
                }
              }}
              required
              error={!!formErrors.mail} // Mettre le champ en rouge si une erreur est présente
              helperText={formErrors.mail} // Afficher le texte d'erreur sous le champ
              sx={{ mb: 2 }}
            />
            <TextField
              fullWidth
              label="Téléphone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              sx={{ mb: 2 }}
            />
            <TextField
              fullWidth
              label="Message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              required
              multiline
              rows={4}
              sx={{ mb: 2 }}
            />
            <Button type="submit" variant="contained" color="primary">
              Envoyer
            </Button>
          </form>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Contact;