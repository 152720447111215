// src/components/Footer.js
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Typography, Divider, Button } from '@mui/material'; // Ajout de l'import Typography
import { setCookie, getCookie } from '../utils/Cookies'; // Import des fonctions cookies

const currentYear = new Date().getFullYear();

const FooterContainer = styled.footer`
  background-color: white;
  padding: 40px 0;
  font-family: 'Atrament-web', sans-serif;
  text-align: center;
  max-width: 1200px; /* Limite la largeur maximale */
  margin: 0 auto; /* Centre le contenu horizontalement */

  @media (max-width: 768px) {
    padding: 20px 0;
  }

  @media (max-width: 480px) {
    padding: 15px 0;
  }
`;

const FooterContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
  padding: 0 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Column = styled.div`
  flex: 1;
  text-align: left;
    &:first-child {
    text-align: center; /* Centre le texte uniquement dans la première colonne */
  }

  @media (max-width: 768px) {
    text-align: center;
    margin-bottom: 20px;
  }
`;

const FooterText = styled.p`
  margin: 10px 0;

  @media (max-width: 768px) {
    font-size: 14px;
  }

  @media (max-width: 480px) {
    font-size: 12px;
  }
`;

const Logo = styled.img`
  width: 120px;
  height: auto;


  @media (max-width: 768px) {
    width: 100px;
  }

  @media (max-width: 480px) {
    width: 80px;
  }
`;

const SocialIcons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  img {
    width: 31px;
    height: 31px;

    @media (max-width: 768px) {
      width: 20px;
      height: 20px;
    }

    @media (max-width: 480px) {
      width: 16px;
      height: 16px;
    }
  }
`;

const Banner = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #f8f9fa;
  color: #333;
  padding: 15px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 10px;
  }
`;

const LegalLink = styled.a`
  color: black;
  text-decoration: none;
  margin-left: 5px;

  &:hover {
    text-decoration: underline;
  }
`;

const Footer = () => {

  const [showBanner, setShowBanner] = useState(false);

  // Vérifie le cookie au chargement
  useEffect(() => {
    const cookieConsent = getCookie('cookieConsent');
    if (!cookieConsent) {
      setShowBanner(true);
    }
  }, []);

  const handleAccept = () => {
    setCookie('cookieConsent', 'true', 365); // Consentement valable 1 an
    setShowBanner(false);
  };

  return (
    <>
  <Divider sx={{ my: 4, ml : 3, mr: 3 }} />
    <FooterContainer>
      <FooterContent>
        {/* Colonne 1 : Logo */}
        <Column>
          <Logo src="/assets/footage-now-Neon-rond.png" alt="Logo Footage-Now" />
          <Typography variant="h6" gutterBottom>FOOTAGE-NOW</Typography>
          <FooterText>Votre professionnel de l'image</FooterText>
        </Column>

        {/* Colonne 2 : Domaines d'intervention */}
        <Column>
          <Typography variant="h6" gutterBottom>Domaines d'intervention</Typography>
          <FooterText>Tourisme et Documentaires</FooterText>
          <FooterText>Inspection techniques</FooterText>
          <FooterText>Événementiel</FooterText>
          <FooterText>Institutionnelles, Événementiels et Privés</FooterText>
          <FooterText>Immobilier</FooterText>
          <FooterText>FPV</FooterText>
        </Column>

        {/* Colonne 3 : Secteurs d'intervention */}
        <Column>
          <Typography variant="h6" gutterBottom>Secteurs d'intervention</Typography>
          <FooterText>Montbéliard, Belfort et Héricourt</FooterText>
          <FooterText>Doubs</FooterText>
          <FooterText>Franche-Comté</FooterText>
          <FooterText>Grand-Est</FooterText>
          <FooterText>France</FooterText>
          <FooterText>Suisse</FooterText>
        </Column>
      </FooterContent>

      <Divider sx={{ my: 4 }} />
      {/* Ligne inférieure */}
      <FooterText>
          © Footage-Now 2024 - {currentYear} | 
          <LegalLink href="/mentions-legales" target="_blank">
            Mentions légales
          </LegalLink>
      </FooterText>
      <SocialIcons>
        <a href="https://www.instagram.com/seb_footagenow" rel="noreferrer" target="_blank">
          <img src="/assets/black_instagram.jpg" alt="Instagram" />
        </a>
        <a href="https://www.youtube.com/@footage-now" rel="noreferrer" target="_blank">
          <img src="/assets/black_youtube.jpg" alt="YouTube" />
        </a>
      </SocialIcons>

      {showBanner && (
        <Banner>
          <Typography variant="body2">
            Nous utilisons des cookies pour améliorer votre expérience utilisateur. En continuant, vous acceptez notre
            <LegalLink href="/politique-de-confidentialite" target="_blank">
              politique de confidentialité
            </LegalLink>.
          </Typography>
          <Button variant="contained" color="primary" onClick={handleAccept}>
            Accepter
          </Button>
        </Banner>
      )}

    </FooterContainer>
    </>
  );
};

export default Footer;
