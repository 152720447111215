import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Grid, Typography, Box, Button, Card, CardMedia, CardContent, Container, Divider } from '@mui/material';

const Immobiliers = () => {
  return (
    <>
    <Helmet>
      <title>Prestation Drone Immobilier - Photos et Vidéos Aériennes Professionnelles à Montbéliard, Belfort, Héricourt - Footage-Now</title>
      <meta name="description" content="Valorisez vos biens immobiliers avec nos prestations drone. Captation de photos et vidéos aériennes en haute qualité pour annonces et promotions immobilières." />
    </Helmet>
    <Box sx={{ py: 6 }}>
      <Container maxWidth="lg">
        {/* En-tête */}
        <Typography variant="h3" align="center" gutterBottom sx={{ fontWeight: 'bold' }}>
          Prestations Drone pour l'Immobilier
        </Typography>
        <Typography variant="h6" align="center" paragraph>
          Mettez en valeur vos biens immobiliers avec des prises de vue aériennes spectaculaires. 
          Offrez une perspective unique qui attire l’attention des acheteurs et maximise vos ventes à <strong>Montbéliard</strong>, <strong>Belfort</strong>, et <strong>Héricourt</strong>.
        </Typography>
        <Divider sx={{ my: 5 }} />

        {/* Contenu principal */}
        <Grid container spacing={4}>
          {/* Section Résidentiel */}
          <Grid item xs={12} md={6}>
            <Card sx={{ boxShadow: 3 }}>
              <CardMedia
                component="img"
                height="300"
                image="/assets/images/immobilier-residentiel.jpg"
                alt="Vue aérienne d'une maison"
              />
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  Mettez en valeur vos propriétés résidentielles
                </Typography>
                <Typography variant="body1" paragraph>
                  Attirez plus d’acheteurs en présentant vos biens sous leur meilleur jour. Les prises de vue aériennes 
                  offrent une vue d’ensemble du terrain, de l’architecture, et des environs, permettant de mettre en avant 
                  tous les atouts d’une propriété.
                </Typography>
                <Typography variant="body1" paragraph>
                  Que vous soyez agent immobilier, promoteur ou propriétaire, nos services vous aident à capter 
                  l’attention et à différencier vos annonces dans un marché compétitif.
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          {/* Section Commercial */}
          <Grid item xs={12} md={6}>
            <Card sx={{ boxShadow: 3 }}>
              <CardMedia
                component="img"
                height="300"
                image="/assets/images/immobilier-commercial.jpg"
                alt="Vue aérienne d'un bâtiment commercial"
              />
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  Valorisez vos biens commerciaux
                </Typography>
                <Typography variant="body1" paragraph>
                  Présentez vos bureaux, commerces ou bâtiments industriels avec des images de qualité professionnelle. 
                  Nos drones permettent de capturer des angles impressionnants, idéaux pour les présentations aux investisseurs 
                  ou pour les campagnes marketing.
                </Typography>
                <Typography variant="body1" paragraph>
                  Offrez une vue complète des infrastructures, des accès, et des emplacements stratégiques pour séduire vos 
                  prospects et accélérer vos ventes ou locations.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <Divider sx={{ my: 5 }} />

        {/* Section d'appel à l'action */}
        <Box sx={{ textAlign: 'center', mt: 6 }}>
          <Typography variant="h4" gutterBottom>
            Sublimez vos biens immobiliers dès aujourd'hui
          </Typography>
          <Typography variant="body1" paragraph>
            Grâce à nos drones et notre expertise, nous vous aidons à maximiser l'impact visuel de vos biens immobiliers. 
            Contactez-nous pour en savoir plus et obtenir un devis adapté à vos besoins.
          </Typography>
          <Button variant="contained" color="primary" size="large" href="/contact">
            Contactez-nous
          </Button>
        </Box>
      </Container>
    </Box>
  </>
  );
};

export default Immobiliers;
