import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Grid, Typography, Box, Button, Card, CardMedia, CardContent, Container, Divider } from '@mui/material';

const Tourisme = () => {
  return (
    <>
    <Helmet>
      <title>Prestation Drone spécialsé dans le tourisme et les documentaires à Montbéliard, Belfort, Héricourt - Footage-Now</title>
      <meta name="description" content="Prestations drone pour le tourisme et les documentaires. Captation de vidéos et photos aériennes pour mettre en valeur vos projets culturels ou touristiques" />
    </Helmet>

    <Box sx={{ py: 6 }}>
      <Container maxWidth="lg">
        {/* En-tête */}
        <Typography variant="h3" align="center" gutterBottom sx={{ fontWeight: 'bold' }}>
          Prestations Drone Photo & Vidéo
        </Typography>
        <Typography variant="h6" align="center" paragraph>
          Sublimez vos paysages, mettez en valeur vos sites touristiques, et racontez vos histoires à travers des
          documentaires époustouflants grâce à nos services de drone à <strong>Montbéliard</strong>, <strong>Belfort</strong>, et <strong>Héricourt</strong>.
        </Typography>

        <Divider sx={{ my: 2 }} />

        {/* Contenu principal */}
        <Grid container spacing={4} sx={{ mt: 1 }}>
          {/* Section Tourisme */}
          <Grid item xs={12} md={6}>
            <Card sx={{ boxShadow: 3 }}>
              <CardMedia
                component="img"
                height="300"
                image="/assets/images/chateau-de-belvoir.png"
                alt="Site touristique vu par drone"
              />
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  Mettez en valeur vos sites touristiques
                </Typography>
                <Typography variant="body1" paragraph>
                  Découvrez les merveilles de Montbéliard, Belfort, et Héricourt sous un nouvel angle grâce à nos prestations de drone.
                </Typography>
                <Typography variant="body1" paragraph>
                  Capturer des paysages naturels époustouflants, valoriser des sites touristiques ou mettre en 
                  avant le patrimoine historique de la région, nos prises de vue aériennes subliment chaque détail. 
                </Typography>
                <Typography variant="body1" paragraph>
                  Avec notre expertise en vidéo drone, nous offrons des services sur mesure pour promouvoir le tourisme local et révéler 
                  la beauté unique de vos destinations préférées. 
                </Typography>
                <Typography variant="body1" paragraph>
                  Faites appel à un prestataire drone professionnel pour booster l’attractivité de vos projets touristiques.
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          {/* Section Documentaires */}
          <Grid item xs={12} md={6}>
            <Card sx={{ boxShadow: 3 }}>
              <CardMedia
                component="img"
                height="300"
                image="/assets/images/documentaires.jpg"
                alt="Documentaire aérien"
              />
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  Créez des documentaires inoubliables
                </Typography>
                <Typography variant="body1" paragraph>
                  Transformez vos récits en images captivantes grâce à nos prestations drone à Montbéliard, Belfort, et Héricourt. 
                </Typography>
                <Typography variant="body1" paragraph>
                  Que vous réalisiez un documentaire sur la nature, le patrimoine ou des histoires humaines, 
                  nos prises de vue aériennes offrent une perspective saisissante et cinématographique.
                </Typography>
                <Typography variant="body1" paragraph>
                  Avec des images en haute définition et un rendu de qualité cinéma, nos drones sont l’outil 
                  idéal pour capturer l’essence de vos sujets et enrichir vos productions audiovisuelles.
                </Typography>
                <Typography variant="body1" paragraph>
                  Laissez-nous vous accompagner pour sublimer vos projets documentaires.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <Divider sx={{ my: 5 }} />

        {/* Section FPV */}
        <Box sx={{ mt: 6 }}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <CardMedia
                component="img"
                height="300"
                image="/assets/images/drone-fpv.jpg"
                alt="Prestation FPV"
                sx={{ borderRadius: 2 }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h5" gutterBottom>
                Découvrez l'immersion avec nos prestations FPV
              </Typography>
              <Typography>
                Plongez dans l'univers immersif des <strong>prestations drone</strong> FPV.
              </Typography>
              <Typography variant="body1" paragraph>
                Offrant des <strong>prises de vue dynamiques</strong> et spectaculaires, les <strong>drones FPV</strong> sont parfait pour des vidéos captivantes
                dans des domaines tels que le <strong>tourisme</strong>, les <strong>documentaires</strong>, <strong>l'immobilier</strong> ou encore les <strong>publicités</strong>.
              </Typography>
              <Typography variant="body1" paragraph>
                Avec des <strong>séquences ultra-immersives</strong>, nous donnons vie à vos idées en apportant une touche de modernité et de créativité. 
                Optez pour une <strong>expérience visuelle</strong> inoubliable grâce à nos drones FPV, conçus pour dépasser vos attentes.
              </Typography>
              <Button variant="outlined" color="primary" href="/drone-fpv">
                  En savoir plus
              </Button>
            </Grid>
          </Grid>
        </Box>

        <Divider sx={{ my: 5 }} />

        {/* Section d'appel à l'action */}
        <Box sx={{ textAlign: 'center', mt: 6 }}>
          <Typography variant="h4" gutterBottom>
            Transformez vos idées en réalité !
          </Typography>
          <Typography variant="body1" paragraph>
            Grâce à notre expertise et à notre équipement de pointe, nous vous offrons des visuels qui 
            dépassent vos attentes. Contactez-nous dès aujourd'hui pour échanger sur vos besoins et 
            découvrir comment nous pouvons transformer vos idées en réalité.
          </Typography>
          <Button variant="contained" color="primary" size="large" href="/contact">
            Contactez-nous
          </Button>
        </Box>
      </Container>
    </Box>
    </>
  );
};

export default Tourisme;
