import React from 'react';
import { Container, Typography, Box, Divider } from '@mui/material';

const MentionsLegales = () => {
  return (
    <Box sx={{ py: 6 }}>
      <Container maxWidth="md">
        <Typography variant="h3" align="center" gutterBottom>
          Mentions Légales
        </Typography>
        <Divider sx={{ my: 4 }} />

        {/* Informations sur l'éditeur */}
        <Typography variant="h5" gutterBottom>
            Propriétaire et Éditeur du site
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>Nom de la société :</strong> Footage-Now<br />
          <strong>Forme juridique :</strong> Micro Entreprise<br />
          <strong>Adresse :</strong> 35 Bis rue du grand Faubourg 25460 Etupes, France<br />
          <strong>Téléphone :</strong> +33 6 95 92 78 63<br />
          <strong>Email :</strong> sebastien@footage-now.video<br />
          <strong>SIRET :</strong> 531 321 180 000 22<br />
        </Typography>

        <Divider sx={{ my: 4 }} />

        {/* Informations sur l'hébergeur */}
        <Typography variant="h5" gutterBottom>
          Hébergement du site
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>Hébergeur :</strong> Ionos<br />
          <strong>Adresse :</strong> 7 place de la Gare 57200 Sarreguemines, France<br />
          <strong>Téléphone :</strong> +33 9 70 80 89 11<br />
          <strong>Site web :</strong> <a href="https://www.ionos.fr/" target="_blank" rel="noopener noreferrer">www.ionos.fr</a>
        </Typography>

        <Divider sx={{ my: 4 }} />

        {/* Propriété intellectuelle */}
        <Typography variant="h5" gutterBottom>
          Propriété intellectuelle
        </Typography>
        <Typography variant="body1" paragraph>
          Tous les contenus présents sur ce site (textes, images, vidéos, logos, etc.) sont protégés par le droit d'auteur. 
          Toute reproduction, distribution, modification ou utilisation sans autorisation écrite préalable est strictement interdite.
        </Typography>

        <Divider sx={{ my: 4 }} />

        {/* Données personnelles */}
        <Typography variant="h5" gutterBottom>
          Données personnelles
        </Typography>
        <Typography variant="body1" paragraph>
          Les informations collectées via le formulaire de contact sont destinées uniquement à répondre à vos demandes. 
          Conformément au Règlement Général sur la Protection des Données (RGPD), vous disposez d'un droit d'accès, de 
          rectification et de suppression de vos données. Pour exercer ces droits, contactez-nous à l'adresse email 
          mentionnée ci-dessus.
        </Typography>

        <Divider sx={{ my: 4 }} />

        {/* Responsabilité */}
        <Typography variant="h5" gutterBottom>
          Responsabilité
        </Typography>
        <Typography variant="body1" paragraph>
          Footage-Now décline toute responsabilité en cas de dommages directs ou indirects résultant de l'accès ou de 
          l'utilisation de ce site. Les liens externes présents sur le site ne sont pas sous notre contrôle et nous ne 
          pouvons être tenus responsables de leur contenu.
        </Typography>
      </Container>
    </Box>
  );
};

export default MentionsLegales;
