import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Grid, Typography, Box, Button, Card, CardMedia, CardContent, Container, Divider } from '@mui/material';

const InspectionTechniques = () => {
  return (
    <>
    <Helmet>
      <title>Inspection Technique par Drone - Contrôle Aérien Précis & Sécurisé à Montbéliard, Belfort, Héricourt - Footage-Now</title>
      <meta name="description" content="Faites appel à nos services d'inspection technique par drone. Une solution rapide, précise et sécurisée pour le contrôle de bâtiments, toitures et infrastructures." />
    </Helmet>
    <Box sx={{ py: 6 }}>
      <Container maxWidth="lg">
        {/* En-tête */}
        <Typography variant="h3" align="center" gutterBottom sx={{ fontWeight: 'bold' }}>
          Inspections Techniques par Drone
        </Typography>
        <Typography variant="h6" align="center" paragraph>
          Accédez à des zones difficiles et obtenez des données précises grâce à nos drones spécialisés. 
          Une solution rapide, sécurisée et économique pour vos inspections techniques à <strong>Montbéliard</strong>, <strong>Belfort</strong>, et <strong>Héricourt</strong>
        </Typography>
        <Divider sx={{ my: 5 }} />
        {/* Contenu principal */}
        <Grid container spacing={4}>
          {/* Section Bâtiments et infrastructures */}
          <Grid item xs={12} md={6}>
            <Card sx={{ boxShadow: 3 }}>
              <CardMedia
                component="img"
                height="300"
                image="/assets/images/inspection-toiture.jpg"
                alt="Inspection d'un bâtiment par drone"
              />
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  Inspections de bâtiments et infrastructures
                </Typography>
                <Typography variant="body1" paragraph>
                  Réalisez des inspections détaillées de vos bâtiments, toitures, et infrastructures 
                  sans mettre en danger vos équipes. Nos drones équipés de capteurs haute résolution permettent 
                  de détecter les défauts structurels, les fissures et les points de faiblesse, même dans les zones 
                  difficiles d'accès.
                </Typography>
                <Typography variant="body1" paragraph>
                  Gagnez du temps et réduisez les coûts en remplaçant les méthodes traditionnelles (échafaudages, 
                  nacelles) par des solutions aériennes fiables et rapides.
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          {/* Section Inspections industrielles */}
          <Grid item xs={12} md={6}>
            <Card sx={{ boxShadow: 3 }}>
              <CardMedia
                component="img"
                height="300"
                image="/assets/images/inspection-eolienne.jpg"
                alt="Inspection par drone"
              />
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  Inspections industrielles et énergétiques
                </Typography>
                <Typography variant="body1" paragraph>
                  Nos drones sont idéaux pour inspecter des installations industrielles complexes telles que les 
                  pipelines, les éoliennes, les centrales solaires, ou les lignes à haute tension. Grâce à des 
                  capteurs thermiques et optiques, nous identifions les anomalies rapidement, réduisant les temps 
                  d'arrêt et augmentant la sécurité.
                </Typography>
                <Typography variant="body1" paragraph>
                  Protégez vos équipes et optimisez vos processus avec des données précises, capturées en toute 
                  sécurité et sans interruption de vos opérations.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Divider sx={{ my: 5 }} />
        {/* Section d'appel à l'action */}
        <Box sx={{ textAlign: 'center', mt: 6 }}>
          <Typography variant="h4" gutterBottom>
            Fiabilité et expertise pour vos inspections techniques
          </Typography>
          <Typography variant="body1" paragraph>
            Avec nos drones et notre expertise, vos inspections deviennent plus simples, plus sûres et plus 
            efficaces. Contactez-nous pour discuter de vos besoins spécifiques et obtenir un devis personnalisé.
          </Typography>
          <Button variant="contained" color="primary" size="large" href="/contact">
            Contactez-nous
          </Button>
        </Box>
      </Container>
    </Box>
    </>
  );
};

export default InspectionTechniques;
