// src/Index.js
import React from 'react';
import { Container, Grid, Typography, CardMedia, Button, Divider, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const Index = () => {
  return (
    <>
      <Helmet>
        <title>Prestation Aérienne par Drone - Montbéliard, Belfort, Héricourt - Footage-Now</title>
        <meta name="description" content="Services de captation vidéo et photo par drone à Montbéliard, Belfort et Héricourt. Des prises de vues aériennes professionnelles pour vos projets immobiliers, publicitaires et événementiels" />
      </Helmet>

      {/* Header avec vidéo */}
      <header style={{ position: 'relative', height: '30vh', overflow: 'hidden' }}>
        <video
          autoPlay
          loop
          muted
          poster="/assets/images/video-placeholder.jpg" // Chemin vers l'image de préchargement
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            zIndex: -1,
          }}
        >
          <source src="/assets/videos/drone-intro.mp4" type="video/mp4" />
          Votre navigateur ne supporte pas la lecture de vidéos.
        </video>
        <Box
          sx={{
            position: 'absolute',
            bottom: 0, // Place le texte au bas de la vidéo
            left: 0,
            width: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Couche sombre pour le contraste
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'white',
            textAlign: 'center',
            boxSizing: 'border-box',
            px: { xs: 2, sm: 4 }, // Padding horizontal
            py: 2, // Padding vertical
            my: 0,
          }}
        >
          <Typography
            variant="h3"
            component="h1"
            gutterBottom
            sx={{
              textTransform: 'uppercase',
              fontSize: 'clamp(0.3rem, 80%, 100%)', // Min: 1.5rem, Préféré: 5% de la largeur, Max: 3rem
              fontWeight: 'bold',
              wordWrap: 'break-word', // Évite le débordement des mots
            }}
          >
            La puissance du drone à votre service
          </Typography>
          <Typography
            variant="h6"
            sx={{
              fontSize: 'clamp(0.3rem, 65%, 75%)', // Min: 1rem, Préféré: 3% de la largeur, Max: 1.5rem

            }}
          >
            Découvrez des perspectives uniques et transformez vos projets en expériences visuelles captivantes.
          </Typography>
        </Box>
      </header>



      {/* Contenu principal */}
      <Container maxWidth={false} sx={{ mt: 4, mb: 4 }}>
        <Divider sx={{ my: 4 }} />

        <Box textAlign="center" mb={4}>
          <Typography
            variant="h4"
            component="h2"
            gutterBottom
            sx={{ textTransform: 'uppercase' }}
          >
            Pourquoi choisir le drone aujourd'hui ?
          </Typography>
          <Typography variant="body1" paragraph>
            Les <strong>drones</strong> offrent une flexibilité sans précédent pour <strong>capturer des images</strong> et <strong>vidéos aériennes</strong>. Que ce soit pour
            des vidéos <strong>cinématographiques</strong>, des <strong>inspections techniques</strong> ou des <strong>prises de vues artistiques</strong>, le drone ouvre des
            perspectives impossibles à atteindre autrement.
          </Typography>
          <Typography variant="body1" paragraph>
            Avec mes services, vous bénéficiez d'une technologie avancée et d'un savoir-faire qui met vos projets en lumière.
          </Typography>
        </Box>

        <Divider sx={{ my: 4 }} />

        {/* Section sur les activités de drone */}
        <Typography
          variant="h4"
          component="h2"
          align="center"
          gutterBottom
          sx={{ textTransform: 'uppercase' }}
        >
        télépilote de drone professionnel certifié
        </Typography>
        <Typography variant="body1" paragraph align="center">
          <p>Nous intervenons à <strong>Montbéliard</strong>, <strong>Belfort</strong> et <strong>Héricourt</strong> </p>
          <p>Donner une nouvelle <strong>perspective</strong> à vos projets photo et vidéo semble aujourd'hui à la portée de tous. Cependant, réaliser des prises de vue aériennes avec <strong>précision</strong>, <strong>rigueur technique</strong> et dans le <strong>respect total des réglementations</strong> exige le savoir-faire d’un pilote de drone qualifié.</p>
          <p>C’est précisément ce que nous proposons avec <strong>Fooage-Now</strong>, votre partenaire de confiance pour tous vos besoins en <strong>captation aérienne</strong> dans le <strong>Nord Franche-Comté</strong> (Montbéliard, Belfort, Héricourt...) et au-delà.</p>
          <p>Que ce soit pour des missions techniques comme la thermographie, l'inspection d'ouvrages comme les panneaux photovoltaïque et éoliénnes, ou encore dans la mise en valeur de vos bien immobiliers ou pour la réalisation de documentaires, je suis là pour répondre à vos attentes avec professionnalisme.</p>
        </Typography>

        <Divider sx={{ my: 4 }} />

        {/* Domaine de compétences */}
        <Typography
          variant="h4"
          component="h2"
          align="center"
          gutterBottom
          sx={{ textTransform: 'uppercase' }}
        >
          Domaines d'expertise
        </Typography>

        {/* Section 1 */}
        <Grid container spacing={4} sx={{ my: 4 }}>
          <Grid item xs={12} md={6} lg={4}>
            <CardMedia
              component="img"
              image="/assets/images/dubrovnik.jpg"
              alt="Tourisme & documentaires"
              sx={{ height: 300, borderRadius: 2 }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={8}>
            <Typography variant="h5" component="h3" sx={{ textTransform: 'uppercase', mb: 2 }}>
              Tourisme & documentaires
            </Typography>
            <Typography variant="body1" paragraph>
              Mettez en valeur les paysages, les sites historiques et les lieux emblématiques avec des prises de vue aériennes spectaculaires. 
              Idéal pour créer des documentaires captivants ou des vidéos touristiques inoubliables.
            </Typography>
            <Button variant="outlined" color="primary" component={Link} to="/tourisme-documentaires">
              En savoir plus
            </Button>
          </Grid>
        </Grid>

          <Divider sx={{ my: 4 }} />

        {/* Section 2 */}
        <Grid container spacing={4} sx={{ my: 4 }}>
          <Grid item xs={12} md={6} lg={8} order={{ xs: 2, md: 1 }}>
            <Typography variant="h5" component="h3" sx={{ textTransform: 'uppercase', mb: 2 }}>
              Inspection techniques
            </Typography>
            <Typography variant="body1" paragraph>
              Effectuez des inspections précises et sécurisées pour vos infrastructures : éoliennes, panneaux solaires, toitures et bien plus. 
              Les drones permettent de réduire les risques et d'optimiser les coûts tout en garantissant des résultats fiables.
            </Typography>
            <Button variant="outlined" color="primary" component={Link} to="/inspection-techniques">
              En savoir plus
            </Button>
          </Grid>
          <Grid item xs={12} md={6} lg={4} order={{ xs: 1, md: 2 }}>
            <CardMedia
              component="img"
              image="/assets/images/inspection-techniques.jpg"
              alt="Inspection techniques"
              sx={{ height: 300, borderRadius: 2 }}
            />
          </Grid>
        </Grid>

        <Divider sx={{ my: 4 }} />

        {/* Section 3 */}
        <Grid container spacing={4} sx={{ my: 4 }}>
          <Grid item xs={12} md={6} lg={4}>
            <CardMedia
              component="img"
              image="/assets/images/institutionnelles-evenementiels.jpg"
              alt="Institutionnelles & Evènementiels"
              sx={{ height: 300, borderRadius: 2 }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={8}>
            <Typography variant="h5" component="h3" sx={{ textTransform: 'uppercase', mb: 2 }}>
              Institutionnelles, Evènementiels & Privés
            </Typography>
            <Typography variant="body1" paragraph>
              Immortalisez vos événements ou communiquez avec élégance pour vos projets institutionnels. 
              Que ce soit pour des conférences, des concerts ou des vidéos corporate, le drone apporte une dimension unique.
            </Typography>
            <Button variant="outlined" color="primary" component={Link} to="/institutionnelles-evenementiels">
              En savoir plus
            </Button>
          </Grid>
        </Grid>

        <Divider sx={{ my: 4 }} />

        {/* Section 4 */}
        <Grid container spacing={4} sx={{ my: 4 }}>
          <Grid item xs={12} md={6} lg={8} order={{ xs: 2, md: 1 }}>
            <Typography variant="h5" component="h3" sx={{ textTransform: 'uppercase', mb: 2 }}>
              Immobiliers
            </Typography>
            <Typography variant="body1" paragraph>
              Sublimez vos biens immobiliers avec des vues aériennes qui attirent l'attention. 
              Idéal pour les agents immobiliers, promoteurs et architectes cherchant à valoriser leurs propriétés.
            </Typography>
            <Button variant="outlined" color="primary" component={Link} to="/immobiliers">
              En savoir plus
            </Button>
          </Grid>
          <Grid item xs={12} md={6} lg={4} order={{ xs: 1, md: 2 }}>
            <CardMedia
              component="img"
              image="/assets/images/immobilier.jpg"
              alt="Immobiliers"
              sx={{ height: 300, borderRadius: 2 }}
            />
          </Grid>
        </Grid>

        <Divider sx={{ my: 4 }} />

        {/* Section 3 */}
        <Grid container spacing={4} sx={{ my: 4 }}>
          <Grid item xs={12} md={6} lg={4}>
            <CardMedia
              component="img"
              image="/assets/images/drone-fpv.jpg"
              alt="Drone FPV"
              sx={{ height: 300, borderRadius: 2 }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={8}>
            <Typography variant="h5" component="h3" sx={{ textTransform: 'uppercase', mb: 2 }}>
              Plans cinématographiques
            </Typography>
            <Typography variant="body1" paragraph>
              Découvrez l'art de la captation avec des drones FPV. Offrant une expérience visuelle unique et des perspectives captivantes, ces drones apportent une nouvelle dimension à vos vidéos.
            </Typography>
            <Button variant="outlined" color="primary" component={Link} to="/drone-fpv">
              En savoir plus
            </Button>
          </Grid>
        </Grid>

        <Divider sx={{ my: 4 }} />

        {/* Section finale */}
        <Box textAlign="center" my={4}>
          <Typography
            variant="h4"
            component="h2"
            gutterBottom
            sx={{ textTransform: 'uppercase' }}
          >
            Prêt à révolutionner vos projets ?
          </Typography>
          <Typography variant="body1" paragraph>
            Avec nos compétences et notre équipements de pointe, nous sommes là pour transformer vos idées en réalité visuelle.
          </Typography>
          <Button variant="contained" color="primary" size="large" component={Link} to="/contact">
            Contactez-Nous
          </Button>
        </Box>
      </Container>
    </>
  );
};

export default Index;
